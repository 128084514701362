.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.form-box {
  width: 520px;
  border: thin solid #ccc;
  padding: 40px 20px 40px;
  border-radius: 8px;
}
