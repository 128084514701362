.ant-pagination {
  padding: 16px 0;
  margin: 10px 0 0 !important;
  position: sticky;
  bottom: -16px;
  background-color: #fff;
  z-index: 9;
  box-shadow: 0px 4px 10px 1px #0000002e;
}

.detail__topbar {
  top: -16px;
}
