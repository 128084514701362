.drop-over-downward {
  position: relative;
}
.drop-over-downward::after {
  content: "";
  display: block;
  position: absolute;
  right: -5px;
  top: 0;
  height: 100%;
  width: 0;
  border-left: 2px dashed rgb(47, 142, 244);
}
.drop-over-upward {
  position: relative;
}
.drop-over-upward::after {
  content: "";
  display: block;
  position: absolute;
  left: -5px;
  top: 0;
  height: 100%;
  width: 0;
  border-left: 2px dashed rgb(47, 142, 244);
}
