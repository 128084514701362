.App {
  width: 100%;
}

.crop-container {
  height: 400px;
  width: 100%;
  position: relative;
}

.controls {
  padding: 22px 0px;
}
